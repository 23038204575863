<template>
  <Basket />
</template>

<script>
import Basket from './components/Basket.vue';

export default {
  name: 'App',
  components: {
    Basket,
  },
  data() {
    return {
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprotor:api',
        mode: 'class',
      },
    };
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
    clearCoupon() {
      localStorage.setItem('coupon', '');
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);

    this.clearCoupon();
  },
};
</script>

<style lang="scss">
  #basket-big {
    max-width: 1200px;
    width: 100%;

    @media (max-width: 575px) {
      padding: 22px;
    }
  }
</style>
