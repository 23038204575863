<template>
  <div>
    <h2 class="scs-title">Корзина</h2>

    <template v-if="!loading">
      <template v-if="basketStorage.length > 0">
        <p class="scs-text">
          Вам необходимо ознакомиться с
          <a href="/include/rules/rules.zip"  target="_blank">правилами посещения</a>
        </p>

        <p class="scs-text">
          Обслуживание посетителей с электронными сертификатами
          на посещение и номинальными сертификатами происходит на отдельной кассе.
        </p>

        <div class="scs-basket">
          <table>
            <tr>
              <th>Категория билета</th>
              <th class="d-none d-xl-table-cell">Дата</th>
              <th class="d-none d-xl-table-cell">Цена</th>
              <!-- <th>Количество</th> -->
              <th>Скидка</th>
              <th>Сумма</th>
              <th></th>
            </tr>

            <template v-for="(item, index) in basketItems">
              <tr :key="index">
                <td>
                  {{ item.name }}

                  <template v-if="item.time">
                    , {{item.time}}
                  </template>

                  <template v-if="item.visits">
                    , {{item.visits}}
                  </template>

                  <template v-if="item.entry">
                    , {{item.entry}}
                  </template>

                  <template v-if="item.selectTime">
                    , {{item.selectTime}}
                  </template>

                  <div class="d-inline-block d-xl-none">
                    <template v-if="item.date">
                      , {{item.date}}
                    </template>
                  </div>
                </td>
                <td class="d-none d-xl-table-cell">{{ item.date }}</td>
                <td class="d-none d-xl-table-cell">
                  <template v-if="item.weekend">
                    <div class="text-nowrap">{{ item.priceWeekend }} руб.</div>
                  </template>

                  <template v-else-if="item.monday">
                    <div class="text-nowrap">{{ item.priceMonday }} руб.</div>
                  </template>

                  <template v-else>
                    <div class="text-nowrap">{{ item.price }} руб.</div>
                  </template>
                </td>
                <!-- <td>
                  <QuantityForm
                    class="scs-basket__quantity"
                    v-model="basketStorage[index].count"
                    @input="changeCount()"
                  />
                </td> -->
                <td>
                  <template v-if="item.sale">
                    <div class="text-nowrap">
                      <span class="d-md-none">Скидка: </span>
                      {{ item.sale }}%
                    </div>
                  </template>
                </td>
                <td>
                  <div class="text-nowrap">
                    <span class="d-md-none">Сумма: </span>
                    <template v-if="item.weekend">
                      {{ item.priceWeekend * item.count }} руб.
                    </template>

                    <template v-else-if="item.monday">
                      {{ item.priceMonday * item.count }} руб.
                    </template>

                    <template v-else>
                      {{ item.price * item.count }} руб.
                    </template>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    class="scs-delete"
                    @click.prevent="deletItem(index)"
                  ></a>
                </td>
              </tr>
            </template>
          </table>
        </div>

        <div class="row mt-4">
          <div class="col-sm-8 col-md-4 col-xl-3">
            <Input
              v-model="coupon"
              type="text"
              placeholder="Введите купон"
            />
          </div>

          <div class="col-sm-4 col-md-auto">
            <a
              class="scs-btn"
              @click.prevent="getSaleCoupon()"
              v-if="!btnCouponLoading"
            >Применить</a>

            <Preloader v-if="btnCouponLoading"/>
          </div>

          <template v-if="couponMessage">
            <div class="col-12">
              {{ couponMessage }}
            </div>
          </template>

          <div class="col-12">
            <p class="scs-text-bottom">
              Уважаемые гости, при покупке сертификата на посещение по промокоду Именинник
              Максимальное количество сертификатов на посещение на которое предоставляется
              скидка-6 шт, включая посещение именинника. С подробными условиями акции
              Именинника можно ознакомиться в разделе «Акции»
            </p>
          </div>
        </div>

        <div class="scs-total">
          Итого: {{ sum }} руб.

          <!-- <template v-if="saleCouponSum > 0">
            <br>Скидка: {{ saleCouponSum }} руб.
            <br>Итого со скидкой: {{ sum - saleCouponSum }} руб.
          </template> -->
        </div>

        <div class="row justify-content-end mt-4">
          <div class="col-sm-5 col-md-4 col-lg-3">
            <a
              href="#order-app"
              data-fancybox=""
              class="scs-btn popup"
            >Оформить заказ</a>
          </div>
        </div>

        <!-- <p class="scs-text-bottom mt-4">
          Нажимая, вы даёте разрешение на
          <a href="#personal" data-fancybox="" class="popup">обработку персональных данных</a>
          и соглашаетесь с
          <a href="#rules">правилами посещения центра</a>
        </p> -->
      </template>

      <template v-else>
        <p class="text-center">Корзина пуста</p>
      </template>
    </template>

    <template v-else>
      <Preloader />
    </template>
  </div>
</template>

<script>
import axios from 'axios';
// import QuantityForm from '@/components/Quantity.vue';
import Preloader from '@/components/Preloader.vue';
import Input from '@/components/Input.vue';

export default {
  name: 'Basket',
  components: {
    // QuantityForm,
    Preloader,
    Input,
  },
  data() {
    return {
      basketStorage: [],
      productsList: [],
      saleList: [],
      loading: false,
      coupon: '',
      saleCoupon: 0,
      couponMessage: '',
      btnCouponLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    productsId() {
      return this.basketStorage.map((item) => item.id);
    },
    basketItems() {
      return this.basketStorage.map((itemStorage) => {
        const item = { ...itemStorage };

        const findProduct = this.productsList.find((itemProduct) => itemProduct.id === item.id);
        if (findProduct) {
          item.name = findProduct.name;
          item.duration = findProduct.duration;
          item.entry = findProduct.entry;
          item.time = findProduct.time;
          item.visits = findProduct.visits;
          item.price = findProduct.price;
          item.priceWeekend = findProduct.priceWeekend;
          item.priceMonday = findProduct.priceMonday;
          item.priceBeforeSale = findProduct.priceBeforeSale;
          item.saleId = findProduct.saleId;
          item.sale = (findProduct.sale) ? findProduct.sale : 0;
        } else {
          item.price = 0;
        }

        return item;
      });
    },
    sum() {
      return this.basketItems.reduce((sum, item) => {
        let { price } = item;
        price = (item.monday) ? item.priceMonday : price;
        price = (item.weekend) ? item.priceWeekend : price;
        return sum + price * item.count;
      }, 0);
    },
    saleSelectTicket() {
      return this.basketItems.reduce((total, item) => {
        const saleSelectTicket = total;
        const { saleId, count } = item;
        const saleFindIndex = saleSelectTicket.findIndex((sale) => sale.saleId === saleId);

        if (saleFindIndex !== -1) {
          saleSelectTicket[saleFindIndex].count += count;
        } else {
          saleSelectTicket.push({
            saleId,
            count,
          });
        }

        return saleSelectTicket;
      }, []);
    },
    checkSale() {
      return this.saleSelectTicket.map((item) => {
        const sale = this.saleList.find((saleItem) => saleItem.id === item.saleId);

        return {
          saleId: item.saleId,
          check: (sale && sale.count <= item.count),
          sale: (sale) ? sale.sale : 0,
        };
      });
    },
    checkedSaleSum() {
      return this.checkSale.reduce((sumSale, item) => sumSale + ((item.check) ? item.sale : 0), 0);
    },
    allCountTickets() {
      return this.basketItems.reduce((allCount, item) => allCount + item.count, 0);
    },
    checkTiketsForOneDay() {
      const tiketsDate = this.basketItems.map((item) => item.date);

      return ([...new Set(tiketsDate)].length === 1);
    },
    checkTiketsForOneSelectTime() {
      const tiketsSelectTime = this.basketItems.map((item) => item.selectTime);

      return ([...new Set(tiketsSelectTime)].length === 1);
    },
    checkTiketsTypeTicket() {
      return this.basketItems.every((item) => item.type === 'ticket');
    },
    saleCouponSum() {
      let sale = this.sum * (this.saleCoupon / 100);
      sale = Math.floor(sale * 100) / 100;
      return sale;
    },
  },
  methods: {
    getSaleCoupon() {
      this.couponMessage = '';
      this.btnCouponLoading = true;
      this.saleCoupon = 0;

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getSaleCoupon');

      const params = new FormData();
      params.append('coupon', this.coupon);
      params.append('countTickets', this.allCountTickets);
      params.append('tiketsForOneDay', (this.checkTiketsForOneDay
                                && this.checkTiketsForOneSelectTime
                                && this.checkTiketsTypeTicket));

      axios
        .post(url, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          const { data } = response.data;

          const coupon = (data.success) ? this.coupon : '';
          localStorage.setItem('coupon', coupon);

          this.couponMessage = data.message;
          this.saleCoupon = data.sale;
          this.btnCouponLoading = false;

          this.setSale();
        })
        .catch((error) => {
          this.couponMessage = 'Ошибка!';
          this.saleCoupon = 0;
          this.btnCouponLoading = false;
          localStorage.setItem('coupon', '');
          console.log(error);
        });
    },
    clearSaleCoupon() {
      this.coupon = '';
      this.couponMessage = '';
      this.saleCoupon = 0;
      localStorage.setItem('coupon', '');
    },
    getBasketItem(items) {
      if (this.productsId.length > 0) {
        this.loading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'getBasketItem');
        url.searchParams.set('arItemsId', JSON.stringify(items));

        this.productsList = [];
        this.saleList = [];

        axios
          .get(url)
          .then((response) => {
            this.productsList = response.data.data.tickets;
            this.saleList = response.data.data.saleList;

            this.setSale();

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getBasketStorage() {
      this.basketStorage = (JSON.parse(localStorage.getItem('basket'))) || [];
      this.changeBasketCountElem();
    },
    changeBasketCountElem() {
      const elems = document.getElementsByClassName('basket__count');
      for (let i = 0; i < elems.length; i += 1) {
        elems[i].textContent = this.basketStorage.length;
      }
    },
    deletItem(index) {
      this.basketStorage.splice(index, 1);

      localStorage.setItem('basket', JSON.stringify(this.basketStorage));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));
    },
    changeCount() {
      localStorage.setItem('basket', JSON.stringify(this.basketStorage));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));
    },
    onStorageUpdate(event) {
      this.basketStorage = (event.detail) || [];
      this.getBasketItem(this.productsId);
      this.changeBasketCountElem();
      this.clearSaleCoupon();
    },
    setSale() {
      this.productsList = this.productsList.map((item) => {
        const ticket = item;

        if (ticket.saleId) {
          const sale = this.checkSale.find((saleItem) => saleItem.saleId === ticket.saleId);

          if (sale && sale.check && sale.sale > this.saleCoupon) {
            let newPrice = ticket.priceBeforeSale - ticket.priceBeforeSale * (sale.sale / 100);
            newPrice = Math.floor(newPrice * 100) / 100;

            ticket.price = newPrice;
            ticket.sale = sale.sale;
          } else if (this.saleCoupon > 0) {
            let newPrice = ticket.priceBeforeSale - ticket.priceBeforeSale
                           * (this.saleCoupon / 100);
            newPrice = Math.floor(newPrice * 100) / 100;

            ticket.price = newPrice;
            ticket.sale = this.saleCoupon;
          } else {
            ticket.price = ticket.priceBeforeSale;
            ticket.sale = 0;
          }
        }

        return ticket;
      });
    },
  },
  created() {
    window.addEventListener('updateBasket', this.onStorageUpdate);

    this.getBasketStorage();
    this.getBasketItem(this.productsId);
  },
};
</script>

<style lang="scss" scoped>
  .scs-title {
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #0DB2A2;

    @media (max-width: 575px) {
      font-size: 36px;
      line-height: 50px;
    }
  }

  .scs-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    a {
      color: #0DB2A2;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 757px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .scs-text-bottom {
    font-size: 15px;
    line-height: 26px;
    // text-align: right;

    a {
      color: #0DB2A2;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .scs-basket {
    max-width: 100%;
    width: 1200px;
    overflow: auto;

    table {
      width: 100%;
      text-align: center;

      tr {

        @media (max-width: 767px) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          border-bottom: 1px solid #ccc;
          padding-bottom: 15px;
        }
      }

      th {
        font-size: 17px;
        line-height: 26px;
        color: #0DB2A2;
        border-bottom: 1px solid #CCE5E2;
        padding: 15px;

        &:first-child {
          text-align: left;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      td {
        font-size: 17px;
        line-height: 26px;
        padding: 15px 15px 0;

        &:first-child {
          text-align: left;
          width: 100%;
          min-width: 300px;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        &:nth-last-child(3) {

          @media (max-width: 767px) {
            order: 4;
            width: 100%;
            text-align: left;
          }
        }

        &:nth-last-child(2) {

          @media (max-width: 767px) {
            order: 5;
          }
        }
      }

      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  .scs-delete {
    width: 25px;
    height: 25px;
    display: block;
    background-image: url(~@/assets/img/delete.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .scs-total {
    text-align: right;
    margin-top: 40px;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;

    @media (max-width: 575px) {
      text-align: center;
    }
  }

  .scs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #F39712;
    border-radius: 7px;
    height: 45px;
    padding: 0 15px;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &:hover {
      background: #f1b358;
    }

    &--transparent {
      background: transparent;
      border: 2px solid #FFFFFF;

      &:hover {
        background-color: #FFFFFF;
        color: #7DB1AD;
      }
    }
  }
</style>
